import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './pages/landing_page/LandingPage';
import CareersPage from './pages/careers/CareersPage';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import NewsPage from './pages/news/NewsPage';
import ArticlePage from './pages/news/ArticlePage';
import CompanyPage from './pages/CompanyPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { Box, ThemeProvider, createTheme } from '@mui/material';

// Define your theme here
const theme = createTheme({
  typography: {
    fontFamily: 'Space Grotesk, sans-serif',
    h1: {
      fontSize: '2.25rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 500,
    },
  },
});

function App() {
  return (
    <Router>
      {/* Apply ThemeProvider at this level to affect all child components */}
      <ThemeProvider theme={theme}>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Header />
          <Box component="main" flexGrow={1}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/careers" element={<CareersPage />} />
              <Route path="/company" element={<CompanyPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/news/:articleId" element={<ArticlePage />} />
            </Routes>
          </Box>
          <ContactUs />
          <Footer />
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;
