import React from 'react';
import { Box, Typography, Button, Container, useTheme, useMediaQuery } from '@mui/material';
import videoBackground from '../../assets/videos/tulxXSya63_1080p_1709830675.mp4';

const Overview = () => {
  const theme = useTheme();
  // Use Media Query hook to dynamically adjust content based on screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {/* Video Background */}
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -100,
        overflow: 'hidden',
      }}>
        <video autoPlay muted loop style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <source src={videoBackground} type="video/mp4" />
        </video>
      </Box>

      {/* Content */}
      <Container maxWidth="md" sx={{
        minHeight: isSmallScreen ? 'auto' : '100vh', // Adjust minHeight based on screen size
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        pt: { xs: 15, sm: 10, md: 8 },
        pb: 8,
        color: '#FFFFFF',
        zIndex: 1, // Ensure content is above the video background
      }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{
          fontWeight: 'bold',
          mb: 4,
          background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          MozBackgroundClip: 'text',
          MozTextFillColor: 'transparent',
        }}>
          Contextualize Language Models for your Enterprise
        </Typography>
        <Typography variant="subtitle1" sx={{
          display: 'block',
          color: '#ff5f00',
          fontWeight: '500',
          letterSpacing: '0.07em',
          mb: 4,
          fontStyle: 'italic',
        }}>
          Customize • Scale • Comply
        </Typography>
        <Typography variant="h6" sx={{ mb: 4, color: '#FFFFFF', fontWeight: 'bold' }}>
          Tailor language models specifically for your enterprise's unique requirements.
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, color: '#FFFFFF', fontWeight: 'bold' }}>
          Elevate your AI strategy with Zeenith AI ecosystem, designed for enterprises seeking high precision, and governance in AI deployments, ensuring your team leverages the most advanced, production-ready solutions.
        </Typography>
        <Box sx={{ alignSelf: 'flex-start' }}>
          <Button
            component="a"
            href="mailto:info@zeenith.ai"
            variant="contained"
            sx={{
              backgroundColor: '#6c2bd9',
              color: 'white',
              fontWeight: 'bold',
              borderRadius: '20px',
              padding: '6px 16px',
              textTransform: 'none',
              ':hover': {
                backgroundColor: '#8130a2',
                boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.24)',
              },
            }}
          >
            Get in Touch
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Overview;
