import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{
        pt: 12,
        pb: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="h5" gutterBottom>
          Effective as of April 05, 2024
        </Typography>
        <Typography paragraph>
          This Privacy Policy describes how Zeenith AI handles personal information that we collect through our website and other digital properties that link to this Privacy Policy (collectively, the “Service”), as well as through social media, our marketing activities, and other activities described in this Privacy Policy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Personal information we collect
        </Typography>
        <Typography paragraph>
          Information you provide to us. Personal information you may provide to us through the Service or otherwise includes: Contact data, such as your first and last name, professional title, organizational affiliation, email address, and phone number. Profile data, such as the username and password that you may set to establish an online account on the Service, and any other information that you add to your account profile.
        </Typography>

        <Typography paragraph>
        User-generated content, such as comments, questions, feedback, messages, images, photos, videos and other content or information that you submit to, or use with, the Services.
        </Typography>

        <Typography paragraph>
        Communications that we exchange with you, including when you contact us through the Service, social media, or otherwise.
        </Typography>

        <Typography paragraph>
        Marketing data, such as your preferences for receiving our marketing communications and details about your engagement with them.
        </Typography>

        <Typography paragraph>
        Other data not specifically listed here, which we will use as described in this Privacy Policy or as otherwise described at the time of collection.
        </Typography>

        <Typography paragraph>
        Third party sources. We may combine personal information we receive from you with personal information we obtain from other sources, such as:
        </Typography>

        <Typography paragraph>
          <strong>Public sources</strong>, such as government agencies, public records, social media platforms, and other publicly available sources. Data providers, such as information services and data licensors that provide demographic and other information. Marketing partners, such as joint marketing partners and event co-sponsors.
        </Typography>

       <Typography paragraph>
       <strong>Third party services</strong>, such as social media services, that you use to log into, or otherwise link to, your Service account. This data may include your username, profile picture and other information associated with your account on that third party service that is made available to us based on your account settings on that service.
        </Typography>

       <Typography paragraph>
       <strong>Automatic data collection.</strong> We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications and other online services, such as: Device data, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, and general location information such as city, state or geographic area.
        </Typography>

       <Typography paragraph>
       <strong>Online activity data</strong>, such as pages you viewed, how long you spent on a page, the website you visited before browsing to the Service, navigation paths between pages, information about your activity on a page, access times and duration of access, and whether you have opened our emails or clicked links within them.
        </Typography>

       <Typography paragraph>
       <strong>Cookies and similar technologies.</strong> Some of the automatic collection described above is facilitated by the following technologies: Cookies, which are small text files that websites store on user devices and that allow web servers to record users’ web browsing activities and remember their submissions, preferences and login status as they navigate a site. Cookies used on our sites include both “session cookies” that are deleted when a session ends, “persistent cookies” that remain longer, “first party” cookies that we place and “third party” cookies that our third party business partners and service providers place.
        </Typography>

      </Box>
    </Container>
  );
};

export default PrivacyPolicyPage;