import React from 'react';
import { Box, Typography, IconButton, Stack, Link } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box sx={{
      backgroundColor: '#280c44', // Updated to the new deep purple color
      color: '#E0E0E0', // Soft white for text for contrast
      py: 3,
      px: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      mt: 'auto',
      borderTop: '1px solid #432874', // A slightly lighter purple for a subtle border
    }}>
      <Typography variant="body2" sx={{ mb: 2, fontWeight: 'medium' }}>
        © 2024 Zeenith AI |
        <Link component="a" href="mailto:info@zeenith.ai" sx={{ color: 'inherit', textDecoration: 'none', ml: 1 }}>
          info@zeenith.ai
        </Link> |
        <Link component={RouterLink} to="/privacy-policy" sx={{ color: 'inherit', textDecoration: 'none', ml: 1 }}>
          Privacy Policy
        </Link>
      </Typography>
      <Stack direction="row" spacing={2}>
        <IconButton color="inherit" href="https://twitter.com/zeenithai" target="_blank" aria-label="Twitter" sx={{ '&:hover': { color: '#1DA1F2' } }}>
          <TwitterIcon />
        </IconButton>
        <IconButton color="inherit" href="https://www.linkedin.com/company/zeenithai" target="_blank" aria-label="LinkedIn" sx={{ '&:hover': { color: '#0077B5' } }}>
          <LinkedInIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default Footer;
