import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Paper } from '@mui/material';

const jobOpenings = [
  // Uncomment or add job openings here
  // { title: 'Software Engineer', location: 'New York, NY' },
  // { title: 'Product Manager', location: 'Remote' },
];

const CareersPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 16, mb: 12 }}> {/* Increased top and bottom margin for more breathing space */}
      <Paper elevation={6} sx={{
        backgroundColor: '#ffffff', // A clean, white background for the Paper
        borderRadius: 4, // Soften the corners a bit more
        p: 6, // Increase padding for a more spacious feel
        boxShadow: '0px 12px 28px rgba(0, 0, 0, 0.15)', // A deeper shadow for more depth
        display: 'flex', // Use flex to center items vertically and horizontally
        flexDirection: 'column', // Stack items vertically
        alignItems: 'center', // Align items to the center horizontally
        justifyContent: 'center', // Justify content center for a balanced look
      }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{
          fontWeight: 'bold', // Emphasize the heading with bold weight
          mb: 6, // Increase margin between title and content
          color: '#333', // Dark color for strong contrast
          textAlign: 'center', // Center-align the heading for emphasis
        }}>
          Join Our Team
        </Typography>
        <Typography variant="h6" sx={{
          color: '#555', // Slightly lighter for the subheading
          maxWidth: '80%', // Limit the width of the subheading for better readability
          mb: 8, // Increased bottom margin for separation
          textAlign: 'center', // Center-align the subheading to match the heading
        }}>
          Explore career opportunities and become a part of something impactful.
        </Typography>
        {jobOpenings.length > 0 ? (
          <List sx={{ width: '100%' }}> {/* Ensure the list takes up the full width of the container */}
            {jobOpenings.map((job, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{
                  pt: 3,
                  pb: 3, // Increase padding for each item for a more expansive look
                  borderBottom: '1px solid #e0e0e0', // Lighter border color for subtlety
                  '&:last-child': { border: 0 },
                  '&:hover': {
                    backgroundColor: '#f5f5f5', // A gentle hover effect
                  },
                  transition: 'background-color 0.3s ease', // Smooth transition for hover effect
                }}>
                  <ListItemText
                    primary={<Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{job.title}</Typography>}
                    secondary={<Typography variant="body1" sx={{ color: '#666' }}>{job.location}</Typography>}
                  />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="subtitle1" sx={{ color: '#999', mt: 6, textAlign: 'center', maxWidth: '80%' }}>
            Currently, there are no open positions. Check back later for opportunities.
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default CareersPage;
