import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CardMedia, Container, Box } from '@mui/material';
import newsData from './news.json'; // Ensure this path matches your file structure

const ArticlePage = () => {
  const { articleId } = useParams();
  const article = newsData.find((news) => news.id === articleId);
  const [image, setImage] = useState('');

  useEffect(() => {
    const importImage = async () => {
      if (article && article.image) {
        const image = await import(`../../assets/images/${article.image}`);
        setImage(image.default);
      }
    };

    importImage();
  }, [article]);

  if (!article) {
    return <Typography variant="h6" textAlign="center" sx={{ mt: 10 }}>Article not found.</Typography>;
  }

  return (
    <Container sx={{ mt: 16, mb: 12, px: { xs: 2, sm: 3, md: 4 } }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 6, // Adjusted for overall balance
        textAlign: 'center', // Center-align text for consistency
      }}>
        <CardMedia
          component="img"
          image={image}
          alt={article.title}
          sx={{
            height: 'auto',
            width: '100%',
            maxWidth: '800px',
            borderRadius: '12px',
            boxShadow: '0 8px 16px -2px rgba(0, 0, 0, 0.25)',
            mb: 4,
          }}
        />
        <Typography variant="h4" component="h1" gutterBottom sx={{
          fontWeight: 'bold',
          color: '#333',
          mb: 2, // Slight margin adjustment for heading
        }}>
          {article.title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{
          mb: 0, // Minimized space between "Written on" and the content
        }}>
          Written on {article.date} | By {article.author}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{
        textAlign: 'justify',
        lineHeight: '1.75',
        color: '#555',
        mx: 'auto',
        maxWidth: '800px',
        fontSize: '1.05rem',
        '& p': {
          marginBottom: '1.5rem',
        }
      }}>
        {article.content.split('\n\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p> // This helps in better paragraph separation
        ))}
      </Typography>
    </Container>
  );
};

export default ArticlePage;
