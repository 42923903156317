import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Card, CardActionArea, CardMedia, CardContent, Grid } from '@mui/material';
import newsData from './news.json'; // Ensure this path matches your file structure

const NewsPage = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const importImages = async () => {
      const withImages = await Promise.all(newsData.map(async (article) => {
        const image = await import(`../../assets/images/${article.image}`);
        return { ...article, image: image.default };
      }));
      setArticles(withImages);
    };

    importImages();
  }, []);

  return (
    <Box sx={{
      pt: 16, // Increased top padding for more breathing space
      pb: 8, // Increased bottom padding for a balanced appearance
      px: 2, // Keep the side padding consistent
      maxWidth: 'lg', // Limiting the maximum width for a more focused content display
      mx: 'auto', // Centering the box
    }}>
      <Typography variant="h4" component="h1" gutterBottom textAlign="center" sx={{
        fontWeight: 'bold',
        mb: 6, // Increased margin bottom for clear separation from the grid
        color: '#333', // A darker shade for strong contrast and focus
      }}>
        Latest News
      </Typography>
      <Grid container spacing={5} justifyContent="center"> {/* Increased spacing for a more airy grid */}
        {articles.map((news) => (
          <Grid item key={news.id} xs={12} sm={6} md={4}>
            <Card sx={{
              maxWidth: 345,
              m: 'auto', // Centering the card within the grid item
              boxShadow: '0 10px 20px -6px rgba(0,0,0,0.25)', // Softened shadow for a subtle depth
              transition: 'box-shadow 0.3s ease-out, transform 0.3s ease', // Keeping the transition smooth
              '&:hover': {
                boxShadow: '0 15px 24px -6px rgba(0,0,0,0.35)', // Softened shadow on hover for elegance
                transform: 'translateY(-4px)', // Reduced the upward motion for subtlety
              },
              borderRadius: '8px', // Rounded corners for a modern look
            }}>
              <CardActionArea component={Link} to={`/news/${news.id}`}>
                <CardMedia
                  component="img"
                  height="250" // Increased height for better image display
                  image={news.image}
                  alt={news.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div" sx={{
                    fontWeight: '600',
                    color: '#444', // Slightly lighter for readability
                  }}>
                    {news.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" sx={{
                    lineHeight: '1.6', // Improved line height for readability
                  }}>
                    {news.summary}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NewsPage;
