import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const CompanyPage = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{
        mt: 12,
        mb: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        p: 4,
      }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#424242', mb: 4, textAlign: 'left' }}>
          Customizing Contextual Language Models for Precision
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, textAlign: 'left', color: '#424242' }}>
          At Zeenith AI, our mission is to harness the founders' deep industry experience to deliver contextual language models that redefine accuracy and auditability in AI. Our models are crafted with an eye towards the nuances of big enterprise challenges, ensuring each solution is meticulously tailored to meet and exceed the specific needs of our clients.
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, textAlign: 'left', color: '#424242' }}>
          Through rigorous development and ethical AI practices, we prioritize not only the performance but also the reliability and transparency of our systems. Our approach to artificial intelligence goes beyond traditional metrics to encompass the holistic impact of AI on organizations, embedding auditability and accountability into the core of every project.
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'left', color: '#424242' }}>
          Leveraging the latest advancements in AI, coupled with our founders' vast experience, Zeenith AI is at the forefront of crafting intelligent systems that promise not just high accuracy but also actionable insights and unparalleled adaptability. Join us on this journey as we transform the landscape of enterprise AI, making it more auditable, accurate, and aligned with your business goals.
        </Typography>
      </Box>
    </Container>
  );
};

export default CompanyPage;
