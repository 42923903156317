import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const ContactUs = () => {
  return (
    <Box sx={{
      backgroundColor: '#0b0110', // Updated background color
      color: 'white',
      textAlign: 'center',
      py: 8,
      px: { xs: 4, sm: 8 },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
        Drop us a note
      </Typography>
      <Button
        component="a"
        href="mailto:info@zeenith.ai"
        variant="contained"
        sx={{
          backgroundColor: '#6c2bd9', // Button base color
          color: 'white',
          fontWeight: 'bold',
          borderRadius: '20px',
          padding: '10px 24px',
          textTransform: 'none',
          transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
          ':hover': {
            backgroundColor: '#8130a2', // Hover color
            boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.24)',
            transform: 'translateY(-4px)',
          },
        }}
      >
        info@zeenith.ai
      </Button>
    </Box>
  );
};

export default ContactUs;
