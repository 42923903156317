import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import contextIcon from '../../assets/images/contextuallm2.png';
import auditIcon from '../../assets/images/audit_icon3.png';
import effectiveIcon from '../../assets/images/effective_specialization2.png';
import feedbackIcon from '../../assets/images/feedback_loop2.png';

const ApproachBox = ({ image, title, text }) => (
  <Card sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '320px',
    width: '100%',
    maxWidth: '80%',
    m: 'auto',
    p: 3,
    boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
    backgroundImage: 'linear-gradient(145deg, #6c2bd9 0%, #9a4dff 100%)',
    color: '#FFFFFF',
    borderRadius: '16px',
    '& img': {
      width: '80px',
      height: '80px',
      objectFit: 'contain',
      marginBottom: '24px',
    },
    '& .MuiTypography-h6': {
      color: '#FFD700',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    '& .MuiTypography-body1': {
      textAlign: 'center',
    }
  }}>
    <CardContent>
      <img src={image} alt={title} />
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Typography variant="body1">{text}</Typography>
    </CardContent>
  </Card>
);

const CompanyApproach = () => {
  const boxes = [
    { image: contextIcon, title: 'Contextual Language Models', text: 'Our models are pre-trained to optimize for both retrieval and generation so your users get the accurate answers they need.' },
    { image: auditIcon, title: 'Auditability by Design', text: 'Auditability by Design ensures transparent and controllable AI, with our advanced RAG setup enabling precise audits and easy edits to align with your standards.' },
    { image: effectiveIcon, title: 'Effective Specialization', text: 'Our cutting-edge fine-tuning techniques customize the models to your data and guidelines, increasing value to your business.' },
    { image: feedbackIcon, title: 'Efficient Alignment', text: 'Our lightweight mechanisms quickly incorporate user feedback. Your model improves continuously with use, even after deployment.' },
  ];

  return (
    <Box sx={{
      minHeight: '100vh',
      textAlign: 'center',
      backgroundColor: '#4a148c',
      color: '#FFFFFF',
      px: { xs: 2, sm: 4 },
      pt: 8,
      pb: 8,
    }}>
      <Typography variant="h2" gutterBottom sx={{ fontWeight: '600', mb: 4, color: '#FFD700' }}>
        Our Approach
      </Typography>
      <Box sx={{
        backgroundImage: 'linear-gradient(145deg, #6c2bd9 0%, #9a4dff 100%)',
        p: 3,
        borderRadius: '16px',
        marginBottom: '32px',
        maxWidth: '800px', // Reduced width
        mx: 'auto', // Centering the box
      }}>
        <Typography variant="subtitle1" component="div" sx={{
          mb: 4,
          color: '#E0E0E0',
          fontSize: '1rem',
          lineHeight: '1.6',
        }}>
          <Box component="span" sx={{ fontWeight: 'bold', color: '#FFD700' }}>End-to-End Optimization:</Box> Our contextual language model system is meticulously pre-trained, fine-tuned, and aligned as a single, integrated system to achieve unmatched production-level performance. Whether it's for financial research or customer engineering, leverage our platform to realize the transformative value of AI across your enterprise.
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {boxes.map((box, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <ApproachBox {...box} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CompanyApproach;
