import React from 'react';
import { Box } from '@mui/material';
import Overview from './Overview';
import CompanyApproach from './CompanyApproach';

const LandingPage = () => {
  return (
      <Box>
        <Overview />
        <CompanyApproach />
      </Box>
  );
};

export default LandingPage;
