import React, { useState } from 'react';
import {
  AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_2.png';

const buttonStyle = {
  color: 'inherit',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderBottom: '2px solid #ff5f00',
  },
  textTransform: 'none',
  mr: 2,
};

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const menuItems = [
    { label: 'Company', path: '/company' },
    { label: 'Careers', path: '/careers' },
    { label: 'News', path: '/news' },
    { label: 'Contact Us', path: 'mailto:info@zeenith.ai' },
  ];

  const renderMenuItem = (menuItem) => (
    <Button key={menuItem.label} component={menuItem.path.startsWith('mailto') ? "a" : Link} to={menuItem.path.startsWith('mailto') ? undefined : menuItem.path} href={menuItem.path.startsWith('mailto') ? menuItem.path : undefined} sx={buttonStyle}>
      {menuItem.label}
    </Button>
  );

  return (
    <AppBar position="fixed" elevation={0} sx={{
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#fff',
      color: theme.palette.text.primary,
      borderRadius: '8px',
      boxShadow: '0 12px 18px rgba(0,0,0,.2)',
      maxWidth: '1350px',
      width: 'calc(100% - 32px)',
      left: '50%',
      transform: 'translateX(-50%)',
      margin: '16px 0',
    }}>
      <Toolbar sx={{
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1280px',
        mx: 'auto',
      }}>
        {/* Logo always on the left */}
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <Link to="/" style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={logo} alt="Company Logo" style={{ width: '120px', height: 'auto' }} />
          </Link>
        </Box>

        {/* Menu items for non-mobile view aligned to the right */}
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
          {menuItems.map(renderMenuItem)}
        </Box>

        {/* Conditional rendering for mobile view drawer icon with right spacing */}
        {isMobile ? (
          <IconButton
            size="large"
            edge="end"
            aria-label="menu"
            sx={{ color: '#6c2bd9', ml: 'auto', mr: 2 }} // Added right margin
            onClick={handleDrawerToggle}
          >
            {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        ) : null}

      </Toolbar>
      {isMobile && (
        <Drawer
          anchor={'top'}
          open={mobileMenuOpen}
          onClose={handleDrawerToggle}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: '#f7f7f7',
              height: '100vh',
              overflowX: 'hidden',
              width: '100vw',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.3s ease-in-out',
              pt: '80px',
            },
          }}
        >
          {/* Drawer content */}
          <List sx={{ padding: '20px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {menuItems.map((item, index) => (
              <ListItem
                button key={index}
                component={item.label === 'Contact Us' ? "a" : Link}
                to={item.path.startsWith('mailto') ? undefined : item.path}
                href={item.path.startsWith('mailto') ? item.path : undefined}
                onClick={() => setMobileMenuOpen(false)}
                sx={{
                  width: '90%',
                  border: '1px solid #e0e0e0',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                  '&:hover': {
                    backgroundColor: '#6c2bd9',
                    color: '#fff',
                    borderColor: '#6c2bd9',
                  },
                  padding: '15px 20px',
                  borderRadius: '10px',
                  margin: '10px 0',
                  transition: 'all 0.3s ease',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  '& .MuiListItemText-primary': {
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                  },
                }}
              >
                <ListItemText primary={<Typography sx={{ color: 'currentColor', fontSize: 'inherit', fontWeight: 'inherit' }}>{item.label}</Typography>} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </AppBar>
  );
};

export default Header;
